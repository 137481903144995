import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function CarrersPage() {
  return (
    <Layout>
      <SEO title='Careers' />
      <div className="relative bg-gradient-to-r from-altblue-light to-altblue">
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Interested in working with us?</h1>
          <p className="mt-6 text-xl text-gray-100 max-w-3xl">
            We&apos;re hiring the most talented people in engineering, marketing, operations, and finance.   
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center w-full py-48 bg-gray-50'>
        <div className="px-16 py-20 rounded-lg shadow-lg bg-white text-center">
          <p className="text-center text-4xl font-bold mb-10">Attn: Careers</p>
          <p className="text-center text-xl mb-10 max-w-lg">You can contact us at the below email address. Please include a resume, link to your Linkedin profile, and what you&apos;d like to work on.</p>
          <a
            href='mailto:careers@tmtgcorp.com?subject=Attn: Careers'
            className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
            careers@tmtgcorp.com
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default CarrersPage
